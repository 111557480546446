export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ')
}

export const stringDatetimeFormat = (date: string) => {
  const dateTime = new Date(date)
  const year = dateTime.getFullYear()
  const month = dateTime.getMonth() + 1
  const day = dateTime.getDate()
  const hour = dateTime.getHours()
  const minute = dateTime.getMinutes()
  const second = dateTime.getSeconds()

  return `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day} ${hour < 10 ? '0' + hour : hour}:${
    minute < 10 ? '0' + minute : minute
  }:${second < 10 ? '0' + second : second}`
}

export const getDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes())
}

export const stringDatetimeFormatForChart = (date: string) => {
  return new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }).toLowerCase()
}

export const isBiggerDate = (date1: number, date2: number) => {
  return date1 >= date2
}

export const getDuration = (startDateString: string, finishDateString: string) => {
  const diff = Math.round((+new Date(finishDateString) - +new Date(startDateString)) / 1000)
  const hours = Math.floor(diff / 3600) // get hours
  const minutes = Math.floor((diff - hours * 3600) / 60) // get minutes
  const seconds = Math.floor(diff - hours * 3600 - minutes * 60) //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  let hourString = String(hours)
  if (hours < 10) {
    hourString = String('0' + hours)
  }
  let minutesString = String(minutes)
  if (minutes < 10) {
    minutesString = String('0' + minutes)
  }
  let secondsString = String(seconds)
  if (seconds < 10) {
    secondsString = String('0' + seconds)
  }
  const result = hourString + ':' + minutesString + ':' + secondsString // Return is HH : MM : SS
  return result
}

export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const isSameDateTime = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDay() === d2.getDay() &&
    d1.getHours() === d2.getHours() &&
    d1.getMinutes() === d2.getMinutes()
  )
}

export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const oneOrTwo = () => {
  return Math.random() < 0.5 ? 1 : 2
}

export const stringDateTimeNoSecsFormat = (date: string) => {
  const dateTime = new Date(date)
  const year = dateTime.getFullYear()
  const month = dateTime.getMonth() + 1
  const day = dateTime.getDate()
  const hour = dateTime.getHours()
  const minute = dateTime.getMinutes()

  return `${year}/${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day} ${hour < 10 ? '0' + hour : hour}:${
    minute < 10 ? '0' + minute : minute
  }`
}

export const convertStringToSlug = (option: { text: string; slugConvert?: string }): string => {
  const { text, slugConvert = '-' } = option
  let slug
  slug = text.toLowerCase()

  slug = text.toLowerCase()
  slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a')
  slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e')
  slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i')
  slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o')
  slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u')
  slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y')
  slug = slug.replace(/đ/gi, 'd')

  slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '')
  slug = slug.replace(/ /gi, slugConvert)
  slug = slug.replace(/\-\-\-\-\-/gi, '-')
  slug = slug.replace(/\-\-\-\-/gi, '-')
  slug = slug.replace(/\-\-\-/gi, '-')
  slug = slug.replace(/\-\-/gi, '-')
  slug = '@' + slug + '@'
  slug = slug.replace(/\@\-|\-\@|\@/gi, '')

  return slug?.trim()
}

export const formatter = new Intl.NumberFormat('vi-VN', {
  style: 'currency',
  currency: 'VND'
})

export const convertToInternationalCurrencySystem = (labelValue: number | string) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + ' ' + 'Tỷ'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + ' ' + 'Triệu'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + ' ' + 'Nghìn'
    : Math.abs(Number(labelValue))
}

export const formatPhone = (phone: string) => {
  return phone.substring(0, 4) + ' ' + phone.substring(4, 7) + ' ' + phone.substring(7, 11)
}

export const range = (start: number, stop: number, step: number) => {
  return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
}

export const isNumber = (str?: string | number) => {
  if (typeof str != 'string') return false // we only process strings!
  return (
    !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

export const buildThresholdList = (numSteps = 20) => {
  const thresholds = []

  for (let i = 1.0; i <= numSteps; i++) {
    let ratio = i / numSteps
    thresholds.push(ratio)
  }

  thresholds.push(0)
  return thresholds
}

export const covertObjectToStringParam = (o: object) => {
  const transformString = Object.entries(o)
    .map(([key, val]) => {
      if (val !== undefined && val !== null && val !== '') {
        return `${key}=${val}`
      }
    })
    .filter((element) => element !== undefined)
    .join('&')

  return !!transformString ? `?${transformString}` : ''
}
